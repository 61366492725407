.stock-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.image-container {
  padding: 1rem;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 10px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(7, 1fr); /* Creates a 4-column grid */
  grid-gap: 10px; /* Add any gap you want between grid items */
  max-height: 400px;
  overflow-y: auto;
  padding: 1rem;
  max-width: 500px;
}

.grid-item {
  /* Add any additional styling for each grid item if needed */
}

.grid-item:hover {
  cursor: pointer;
  opacity: 0.5;
}

/* Adjust the size of the images inside the grid items if needed */
.grid-item img {
  width: 100%; /* Make the images fill the grid item width */
  height: auto; /* Maintain aspect ratio */
}

.big-image {
  width: 100%;
  height: auto;
  padding: 1rem;
}

.small-images {
  display: flex;
  align-items: flex-end;
  margin-left: 10px;
  flex-wrap: wrap;
  width: 500px; /* Adjust the width based on your preference */
}

.small-images img {
  width: 50px;
  height: 50px;
  margin: 5px; /* Add some margin between the small images */
}

.details {
  text-align: center;
}

.details-content {
  margin-top: 10px;
}

.details h2 {
  font-size: 16px;
  margin-bottom: 5px;
}

.details p {
  font-size: 14px;
  margin-bottom: 10px;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.popup h2 {
  font-size: 24px;
  margin-bottom: 16px;
}

.popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 600px;
  text-align: center; /* Center the content */
  position: relative; /* Add relative positioning */
}

.popup-heading{
  font-weight: bold;
  text-align: left;
  font-size: h1;
}

.form-group {
  margin-bottom: 16px;
  text-align: left; /* Align labels to the left */
}

.form-group label {
  display: block;
  font-size: 16px;
  margin-bottom: 8px;
}

.form-group input {
  width: 95%;
  padding: 15px;
  margin: 5px 0 8px 0;
  font-size: 16px;
  border: none;
  background: #f1f1f1;
}

.send-btn {
  background-color: #007BFF;
  color: #fff;
  padding: 10px 16px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.send-btn:hover {
  background-color: #0056b3;
}

/* Close button styles */
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  background-color: black;
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
}

.close-btn:hover {
  color: #c00a0a;
}

@media (max-width: 1200px) {
  .image-container {
    flex-direction: column; /* Stack big-image and grid-container in a column */
    align-items: center;
    justify-content: center;
  }

  .grid-container {
    grid-template-columns: repeat(7, 1fr); /* Change to 1-column grid */
    max-width: unset; /* Remove the maximum width */
  }

  .grid-item {
    /* Add any additional styling for each grid item if needed */
  }

  /* Adjust the size of the images inside the grid items if needed */
  .grid-item img {
    width: 100%; /* Make the images fill the grid item width */
    height: auto; /* Maintain aspect ratio */
  }

  .big-image {
    width: 100%;
    height: auto;
    padding: 1rem;
    max-width: unset; /* Remove the maximum width */
  }

  /* Adjust the popup width for smaller screens */
  .popup-content {
    width: 90%;
  }
}