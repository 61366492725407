.About{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    pad: 0 2rem;
}

.About .home-intro{
    display: flex;
    gap: 5rem;
    font-weight: bold;
    font-size: 2.5rem;
    justify-content: center;
    color: white;
    text-transform: uppercase;
    padding-top: 4rem;
    padding-bottom: 4rem;
}

.About .image-text-section{
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../assets/about-intro.png");
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.About .intro{
    display: flex;
    gap: 5rem;
    font-weight: bold;
    font-size: 2rem;
    justify-content: center;
    color: rgb(0, 0, 0);
    text-transform: uppercase;
    padding-top: 1rem;
    text-align: center;
}

.about-description{
    display: flex;
    font-size: 1.3rem;
    justify-content: center;
    color: black;
    text-align: center;
    padding-left: 5rem;
    padding-right: 5rem;
}

.About .image-gallery {
    display: flex;
    justify-content: center;
    gap: 2rem;
    padding: 2rem;
    flex-wrap: nowrap;
}

.About .image {
    max-width: 50%;
    box-sizing: border-box;
    margin: 0.5rem;
}

.About .image img {
    width: 100%; /* Make sure the image takes full width */
    height: auto; /* Maintain aspect ratio and prevent overlapping */
}


@media only screen and (max-width: 768px) {
    .About .image-gallery {
        flex-wrap: wrap;
        align-items: center;
    }

    .About .image {
        max-width: 100%;
        margin: 1rem 0;
    }
  }