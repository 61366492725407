.stocks-container {
  display: flex;
}

.left-pane {
  width: 20%;
  padding-left: 2rem;
  padding-top: 2rem;
  padding-right: 2rem;
  /* Add your own styles here */
}

.right-pane {
  width: 80%;
  background-color: whitesmoke;
  padding: 2rem;
}

.category-heading {
  font-size: 1.6rem;
  font-weight: bold;
}

.card-row {
  display: flex;
  flex-wrap: wrap;
  margin: -0.5rem;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  border-radius: 0.1rem;
  /* border: 1px solid rgb(218, 218, 218); */
  padding: 0.1rem;
}

.card-item {
  flex-basis: calc(33.33% - 1rem); /* Adjust the width as needed */
  margin: 0.5rem;
}

.card-item:nth-child(3n) {
  margin-right: 0;
}

.card-image{
  background-color: black;
  padding: 0;
}

.card-image img{
  height: 246px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  opacity: 1;
  transition: .5s ease;
  backface-visibility: hidden;
}

.card-image img:hover{
  opacity: 0.3;
}

.card-name{
  text-align: center;
}

.card-body{
  border: 1px solid rgb(221, 221, 221);
}

.card-body p{
  text-align: center;
}

.card-btn{
  background-color: rgb(78, 2, 2);
  color: white;
  padding: 0.5rem;
  width: 100%;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
}

table {
  border-collapse: collapse;
  width: 100%;
}

td {
  padding: 8px;
  text-align: left;
}

td:nth-child(2) {
  text-align: center;
}

.lblAvailableStock {
  font-size: 1.3rem;
  font-weight: bold;
}

.filter-header{
  background-color: rgb(78, 2, 2);
  padding: 0.2rem;
  color: white;
  text-align: center;
}

.category-list{
  list-style: none;
  padding-left: 0;
}

.category-item a{
  display: flex;
  justify-content: space-between;
  padding: 0.3rem;
}

.year-picker-div{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
}

.datepicker{
  padding: 0.4rem;
  width: 5rem;
}

.search-input{
  padding: 0.4rem;
}

.lbl-model{
  font-weight: bold;
  padding-right: 2rem;
}

@media only screen and (max-width: 1200px) {
  .left-pane{
    display: none;
  }

  .stocks-container {
    flex-direction: column-reverse; /* Reverse the order of panes for mobile view */
  }

  .right-pane {
    width: 100%; /* Use full width for right-pane in mobile view */
  }
}