.header{
    display: flex;
    justify-content: space-between;
    background-color: black;
}

.logo{
    width: 15rem;
    height: 7rem;
    padding-left: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.header-menu{
    list-style: none;
    display: flex;
    gap: 2rem;
    color: white;
    padding: 2.5rem;
    
}

.nav-item:hover{
    color: rgb(221, 87, 87);
    cursor: pointer;
} 

ul a{
    text-decoration: none;
    color: inherit;
}

.nav-item {
    font-size: 1.5rem;
}
.lmct-no{
    color: white;
    padding: 5px;
    font-size: 0.8rem;
}

.menu-button{
    display: none;
    color: white;
    align-items: center;
    padding: 2.5rem;
    font-size: 2rem;
    cursor: pointer;
}

.mobile-menu{
    display: none;
}

@media only screen and (max-width: 768px) {
    .mobile-menu {
        display: none;
        background-color: black;
        width: 100%;
        transition: opacity 0.3s, transform 0.3s;
        opacity: 0;
        transform: translateY(-20px);
    }

    .mobile-menu.open {
        display: flex;
        opacity: 1;
        transform: translateY(0);
    }

    .mobile-items{
        list-style: none;
        display: flex;
        flex-direction: column;
        text-align: center;
        gap: 1.5rem;
        color: white;
        padding: 2rem;
        width: 100%;

    }

    .nav-item:hover{
        color: rgb(221, 87, 87);
        cursor: pointer;
    } 

    .header-menu{
        display: none;
        flex-direction: column;
        width: 100%;
    }

    .mobile-menu{
        background-color: black;
        width: 100%;
    }

    .nav-item{
        width: 100%;
    }

    .menu-button{
        display: flex;
        
    }

}