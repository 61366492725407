.Contact{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    pad: 0 2rem;
}

.Contact .home-intro{
    display: flex;
    gap: 5rem;
    font-weight: bold;
    font-size: 2.5rem;
    justify-content: center;
    color: white;
    text-transform: uppercase;
    padding-top: 4rem;
    padding-bottom: 4rem;
}

.Contact .gmap-frame{
    padding-left: 5rem;
    display: flex;
    justify-content: center;
}

.contact-section{
    display: flex;
    padding-bottom: 2rem;
}

.contact-heading{
    font-size: 1.7rem;
    font-weight: bold;
}

.contact-details{
    padding-left: 7rem;
    padding-top: 1rem;
}

.Contact i{
    font-size: 1.6rem;
    color: rgb(0, 0, 0);
    padding-right: 1.5rem;
    vertical-align: middle;
}

.Contact .contact-item p{
    font-size: 1.2rem;
    color: rgb(0, 0, 0);
    padding: 0.8rem;
    vertical-align: middle;
}

.Contact .contact-item a{
    font-size: 1.2rem;
    color: rgb(0, 0, 0);
    padding: 1rem;
    vertical-align: middle;
    text-decoration: none;
    color: inherit;
}

.contact-item{
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 1200px) {
    .contact-section{
        flex-direction: column;
    }

    .Contact .gmap-frame{
        padding-left: 0;
    }

    .Contact .gmap-frame iframe {
        width: 100%; 
        max-width: 800px; 
        height: 450px; 
        border: 0; 
        padding: 1rem;
    }
}